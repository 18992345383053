import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"

const RecruitingPage = () => (
  <Layout>
    <Head pageTitle="採用情報 - 株式会社GAZIRU" pageLang="ja" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>採用情報</h4>
          <h5>求人募集</h5>
          <br />
          <p>現在、営業・企画職の募集をしています。
            応募を希望する方は、<Link className="text-decoration-none" to="/notification/contact/ja">お問い合わせフォーム</Link>より、気軽にお問い合わせください。</p>

          <table>
            <tbody>
              <tr>
                <th>職種</th>
                <td>営業・企画</td>
              </tr>
              <tr>
                <th>雇用形態</th>
                <td>正社員</td>
              </tr>
              <tr>
                <th>業務内容</th>
                <td>営業、企画など</td>
              </tr>
              <tr>
                <th>給与</th>
                <td>月給（応相談）</td>
              </tr>
              <tr>
                <th>賞与</th>
                <td>年2回</td>
              </tr>
              <tr>
                <th>昇給</th>
                <td>年1回</td>
              </tr>
              <tr>
                <th>勤務地</th>
                <td>東京都世田谷区</td>
              </tr>
              <tr>
                <th>勤務時間</th>
                <td>9:00～17:45（実働7.75時間）</td>
              </tr>
              <tr>
                <th>休暇</th>
                <td>
                  週休2日制（土、日、祝日）<br />
                  年末年始休暇<br />
                  年次休暇<br />
                  夏季休暇 など
                </td>
              </tr>
            </tbody>
          </table>

          <br />

          <p>応募は<Link className="text-decoration-none" to="/notification/contact/ja">以下</Link>からお願いしたします。</p>

          <p><Link className="text-decoration-none" to="/notification/contact/ja">お問い合わせフォーム</Link></p>

          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default RecruitingPage
